import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import SEO from "../components/seo"
import marked from "marked"

const Category = data => {
  const posts = data.data.allSanityPost.edges
  const postLength = posts.length

  return (
    <Layout>
      <SEO
        title="Latest News"
        description={data.data.site.siteMetadata.description}
      />
      <div className="page-headline">
        <div className="container">
          <div className="section-heading text-center">
            <h1>{data.pageContext.title}</h1>
          </div>
        </div>
      </div>
      <div className="blog-section">
        <Container>
          <Row className="justify-content-center">
            {posts.length !== 0 &&
              posts.map((blog, index) => (
                <Col
                  sm={6}
                  md={
                    (posts.length >= 2 && index === 0) ||
                    (posts.length >= 2 && index === 1) ||
                    (posts.length >= 7 && index === postLength - 1) ||
                    (posts.length >= 7 && index === postLength - 2)
                      ? 6
                      : 4
                  }
                  lg={
                    (posts.length >= 2 && index === 0) ||
                    (posts.length >= 2 && index === 1) ||
                    (posts.length >= 7 && index === postLength - 1) ||
                    (posts.length >= 7 && index === postLength - 2)
                      ? 6
                      : 4
                  }
                  key={index}
                  className={
                    (posts.length >= 2 && index === 0) ||
                    (posts.length >= 2 && index === 1)
                      ? "mb-5 pb-100"
                      : "mb-5"
                  }
                >
                  <Link to={`/${blog?.node?.slug?.current}`}>
                    <div
                      className="blog-item bg-light"
                      style={{
                        marginRight:
                          (posts.length > 2 && index === 0 && 30) ||
                          (posts.length >= 7 && index === postLength - 2 && 30),
                        marginLeft:
                          (posts.length > 2 && index === 1 && 30) ||
                          (posts.length >= 7 && index === postLength - 1 && 30),
                      }}
                    >
                      <div
                        className="bg-blog position-relative"
                        style={{
                          backgroundImage: `url(${blog.node?.mainImage?.asset?.fluid?.src})`,
                        }}
                      >
                        <div className="blog-date">
                          <p>
                            <i
                              className="fa fa-calendar-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {blog.node.publishedAt}
                          </p>
                        </div>
                      </div>
                      <div className="blog-body">
                        <h3>{blog.node.title}</h3>
                        <div
                          className="blog-excerpt"
                          dangerouslySetInnerHTML={{
                            __html: marked(blog.node.excerpt || ""),
                          }}
                        />
                        <div className="blog-foot">
                          <Row>
                            <Col md={7}>
                              {blog.node?.authors.length !== 0 && (
                                <p>
                                  <i
                                    className="fa fa-user"
                                    ariaHidden="true"
                                    style={{ color: "#00C1C8" }}
                                  ></i>{" "}
                                  {blog.node?.authors[0].person.name}
                                </p>
                              )}
                            </Col>
                            <Col md={5} className="text-right">
                              <p>
                                <Link
                                  to={`/${blog?.node?.slug?.current}`}
                                  style={{ color: "#00C1C8" }}
                                >
                                  <small>
                                    Read More{" "}
                                    <i
                                      className="fa fa-arrow-right"
                                      ariaHidden="true"
                                    ></i>
                                  </small>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
          </Row>

          <center>
            <Link to="/news" className="common-btn">
              Back to news
            </Link>
          </center>
        </Container>
      </div>
    </Layout>
  )
}

export default Category

export const categoryQuery = graphql`
  query categoryQuery($title: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(
      filter: { categories: { elemMatch: { title: { eq: $title } } } }
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMM-DD-YYYY")
          date: publishedAt(formatString: "MMM YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          excerpt
          slug {
            current
          }
          authors {
            person {
              name
            }
          }
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
